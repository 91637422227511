/* .react-datepicker { */
/* font-family: 'Arial', sans-serif; */
/* } */

.day-outside-month {
  visibility: hidden;
}

.react-datepicker__day--highlight-today {
  background-color: #ffeb3b;
  color: #000;
}

.react-datepicker__day:hover {
  background-color: #ccc;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  border-color: #216ba5;
}

.react-datepicker__header {
  background-color: #216ba5;
}

.react-datepicker__current-month {
  font-weight: bold;
  color: #000000;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: #a58221;
}

.react-datepicker__day-names {
  background: #f58434;
}
.react-datepicker__day-name {
  color: white;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: #4e4e4e;
}

.react-datepicker__day--weekend {
  color: #da0d0d;
}

.custom-day {
  color: #333;
}

.react-datepicker__header {
  background-color: #ffffff;
}

.react-datepicker__month {
  background-color: #ffffff;
}

.react-datepicker {
  min-height: 295px;
  z-index: 9999;
}

.react-datepicker__day--selected {
  background-color: #216ba540;
}
